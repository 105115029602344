import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Css/core.css";
import "./Css/theme-semi-dark.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import App from "./App";
import AppProvider from "./context/appContext";
// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <AppProvider>
    <App />
  </AppProvider>
);

reportWebVitals();
